$(function () {
    let scrollFromTop = $('html').scrollTop(),
        scrollPosition = 0;

    $(window).scroll(function () {
        scrollPosition = $(document).scrollTop();

        if (scrollPosition > 1) {
            $('body').addClass('has-scrolled');
        } else {
            $('body').removeClass('has-scrolled');
        }
    });

    if (scrollFromTop > 1) {
        $('body').addClass('has-scrolled');
    }
});
