<template>
    <div class="cookie-pop-up" v-if="showPopup">
        <div class="cookie-content">
            <div class="cookie-body" v-html="translations.cookie_body"></div>
            <div class="cookie-footer">
                <button type="button" class="footer-btn" @click="toggleModal(true)" v-text="translations.settings"></button>
                <button type="button" class="footer-btn _accept-btn" @click="acceptRequired"  v-text="translations.accept_only_required"></button>
                <button type="button" class="footer-btn _accept-btn" @click="acceptAll" v-text="translations.accept_all"></button>
            </div>
        </div>

        <div class="cookie-modal" v-show="showModal">
            <div class="cookie-modal-content">
                <div class="cookie-modal-header">
                    <h4 class="font-semibold" v-text="translations.modal.title"></h4>
                    <button @click="toggleModal(false)" class="btn-close">&times;</button>
                </div>
                <div class="cookie-modal-body">
                    <div v-html="translations.cookie_body"></div>

                    <div class="cookie-accordion _mt-4">
                        <button
                            type="button"
                            :class="['cookie-accordion-header', {
                                '_open': accordion.required
                            }]"
                            @click="toggleAccordion('required')"
                        >
                            <span v-text="translations.modal.required_cookies"></span>

                            <svg version="1.2" preserveAspectRatio="none" viewBox="0 0 24 24" class="icon-wedge-svg" data-id="e9b3c566e8c14cfea38af128759b91a3" style="opacity: 1; mix-blend-mode: normal; fill: rgb(51, 51, 51); width: 32px; height: 32px;"><path xmlns:default="http://www.w3.org/2000/svg" id="angle-down" d="M17.2,9.84c0-0.09-0.04-0.18-0.1-0.24l-0.52-0.52c-0.13-0.13-0.33-0.14-0.47-0.01c0,0-0.01,0.01-0.01,0.01  l-4.1,4.1l-4.09-4.1C7.78,8.94,7.57,8.94,7.44,9.06c0,0-0.01,0.01-0.01,0.01L6.91,9.6c-0.13,0.13-0.14,0.33-0.01,0.47  c0,0,0.01,0.01,0.01,0.01l4.85,4.85c0.13,0.13,0.33,0.14,0.47,0.01c0,0,0.01-0.01,0.01-0.01l4.85-4.85c0.06-0.06,0.1-0.15,0.1-0.24  l0,0H17.2z" style="fill: rgb(51, 51, 51);"></path></svg>
                        </button>
                        <div :class="['cookie-accordion-content', {
                            '_open': accordion.required
                        }]">
                            <div class="cookie-accordion-content-body" v-html="translations.modal.required_cookies_body"></div>
                        </div>
                    </div>

                    <!-- <div class="cookie-accordion">
                        <button
                            type="button"
                            :class="['cookie-accordion-header', {
                                '_open': accordion.functional
                            }]"
                            @click="toggleAccordion('functional')"
                        >
                            <span class="flex items-center">
                                <span :class="{
                                    'dot _enabled': settings.functional.enabled,
                                    'dot _disabled': !settings.functional.enabled,
                                }"></span>
                                <span v-text="translations.modal.functional_cookies"></span>
                            </span>

                            <svg version="1.2" preserveAspectRatio="none" viewBox="0 0 24 24" class="icon-wedge-svg" data-id="e9b3c566e8c14cfea38af128759b91a3" style="opacity: 1; mix-blend-mode: normal; fill: rgb(51, 51, 51); width: 32px; height: 32px;"><path xmlns:default="http://www.w3.org/2000/svg" id="angle-down" d="M17.2,9.84c0-0.09-0.04-0.18-0.1-0.24l-0.52-0.52c-0.13-0.13-0.33-0.14-0.47-0.01c0,0-0.01,0.01-0.01,0.01  l-4.1,4.1l-4.09-4.1C7.78,8.94,7.57,8.94,7.44,9.06c0,0-0.01,0.01-0.01,0.01L6.91,9.6c-0.13,0.13-0.14,0.33-0.01,0.47  c0,0,0.01,0.01,0.01,0.01l4.85,4.85c0.13,0.13,0.33,0.14,0.47,0.01c0,0,0.01-0.01,0.01-0.01l4.85-4.85c0.06-0.06,0.1-0.15,0.1-0.24  l0,0H17.2z" style="fill: rgb(51, 51, 51);"></path></svg>
                        </button>

                        <div :class="['cookie-accordion-content', {
                            '_open': accordion.functional
                        }]">
                            <div class="cookie-accordion-content-body">
                                <p v-text="translations.modal.functional_cookies_body_1"></p>
                                <div class="w-full flex mt-2">
                                    <div class="w-1/3">
                                        <div class="switch">
                                            <input id="functional" type="checkbox" v-model="settings.functional.enabled" />
                                            <label for="functional" class="_label">
                                                {{ translations.no }}
                                                <span class="_switch-control"></span>
                                                {{ translations.yes }}
                                            </label>
                                        </div>
                                    </div>
                                    <div class="w-2/3" v-html="translations.modal.functional_cookies_body_2"></div>
                                </div>
                            </div>
                        </div>
                    </div> -->

                    <div class="cookie-accordion">
                        <button
                            type="button"
                            :class="['cookie-accordion-header', {
                                '_open': accordion.marketing
                            }]"
                            @click="toggleAccordion('marketing')"
                        >
                            <span class="flex items-center">
                                <span :class="{
                                    'dot _enabled': settings.marketing.enabled,
                                    'dot _disabled': !settings.marketing.enabled,
                                }"></span>
                                <span v-text="translations.modal.marketing_cookies"></span>
                            </span>

                            <svg version="1.2" preserveAspectRatio="none" viewBox="0 0 24 24" class="icon-wedge-svg" data-id="e9b3c566e8c14cfea38af128759b91a3" style="opacity: 1; mix-blend-mode: normal; fill: rgb(51, 51, 51); width: 32px; height: 32px;"><path xmlns:default="http://www.w3.org/2000/svg" id="angle-down" d="M17.2,9.84c0-0.09-0.04-0.18-0.1-0.24l-0.52-0.52c-0.13-0.13-0.33-0.14-0.47-0.01c0,0-0.01,0.01-0.01,0.01  l-4.1,4.1l-4.09-4.1C7.78,8.94,7.57,8.94,7.44,9.06c0,0-0.01,0.01-0.01,0.01L6.91,9.6c-0.13,0.13-0.14,0.33-0.01,0.47  c0,0,0.01,0.01,0.01,0.01l4.85,4.85c0.13,0.13,0.33,0.14,0.47,0.01c0,0,0.01-0.01,0.01-0.01l4.85-4.85c0.06-0.06,0.1-0.15,0.1-0.24  l0,0H17.2z" style="fill: rgb(51, 51, 51);"></path></svg>
                        </button>

                        <div :class="['cookie-accordion-content', {
                            '_open': accordion.marketing
                        }]">
                            <div class="cookie-accordion-content-body">
                                <p v-text="translations.modal.marketing_cookies_body_1"></p>
                                <div class="w-full flex mt-2">
                                    <div class="w-1/3">
                                        <div class="switch">
                                            <input id="marketing" type="checkbox" v-model="settings.marketing.enabled" />
                                            <label for="marketing" class="_label">
                                                {{ translations.no }}
                                                <span class="_switch-control"></span>
                                                {{ translations.yes }}
                                            </label>
                                        </div>
                                    </div>
                                    <div class="w-2/3" v-html="translations.modal.marketing_cookies_body_2"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="cookie-modal-footer">
                    <button type="button" class="mr-3" @click="acceptCurrent" v-text="translations.save_settings"></button>
                    <button type="button" @click="acceptAll" v-text="translations.accept_all"></button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'CookiePopUp',

    props: {
        accepted: {
            required: true,
        },
        translations: {
            required: true,
        }
    },

    data() {
        return {
            showPopup: false,
            showModal: false,
            settings: {
                required: {
                    enabled: true,
                },
                functional: {
                    enabled: true,
                },
                marketing: {
                    enabled: true,
                },
            },
            accordion: {
                required: false,
                functional: false,
                marketing: false,
            }
        }
    },

    created() {
        if (this.accepted) {
            return;
        }

        this.showPopup = true;
    },

    methods: {
        toggleModal(showModal) {
            this.showModal = showModal;
        },

        toggleAccordion(key) {
            this.accordion[key] = !this.accordion[key]
        },

        acceptCurrent() {
            this.setCookie('cookie_consent', JSON.stringify(this.settings));

            this.showPopup = false;
        },

        acceptRequired() {
            this.settings.required.enabled = true;
            this.settings.functional.enabled = false;
            this.settings.marketing.enabled = false;

            this.setCookie('cookie_consent', JSON.stringify(this.settings));

            this.showPopup = false;
        },

        acceptAll() {
            this.settings.required.enabled = true;
            this.settings.functional.enabled = true;
            this.settings.marketing.enabled = true;

            this.setCookie('cookie_consent', JSON.stringify(this.settings));

            this.showPopup = false;
        },

        setCookie(name, value) {
            var d = new Date();
            var durationDays = 365;

            d.setTime(d.getTime() + durationDays * 24 * 60 * 60 * 1000);

            var expires = "expires=" + d.toUTCString();

            document.cookie = name + "=" + value + ";" + expires + ";path=/";
        },
    }
}
</script>

<style lang="scss" scoped>
.cookie-pop-up {
    position: fixed;
    max-width: 100%;
    right: 8px;
    bottom: 8px;
    left: 8px;
    border-radius: 15px;
    box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.16);
    width: auto;
    background: white;
    z-index: 999999;

    .w-1\/3 {
        width: 33.333333%;
    }

    .w-2\/3 {
        width: 66.666667%;
    }

    .w-full {
        width: 100%;
    }

    .flex {
        display: flex;
    }

    .items-center {
        align-items: center;
    }

    .mt-2 {
        margin-top: 8px;
    }

    .mr-3 {
        margin-right: 12px;
    }

    @media screen and (min-width: 768px) {
        max-width: 720px;
        width: 100%;
        left: auto;
    }

    button {
        background: none;
        border: none;
    }

    .cookie-content {
        padding: 16px;
        font-size: 12px;

        &::v-deep{
            p {
                font-size: 12px;
                margin-bottom: 8px;
            }

            ._link {
                color: #002d7d;
            }
        }


        .cookie-body {}
        .cookie-footer {
            display: flex;
            align-items: center;
            margin-top: 16px;

            button {
                transition: all 300ms ease-in-out;

                &.footer-btn {
                    &:hover {
                        color: #002d7d;
                    }

                    &._accept-btn {
                        margin-left: 12px;
                        border-radius: 30px;
                        background-color: #002d7d;
                        color: white;
                        padding: 16px 8px;
                        border: 1px solid #002d7d;

                        &:hover {
                            background-color: white;
                            color: #002d7d;
                        }
                    }
                }
            }
        }
    }

    // Modal window
    .cookie-modal {
        position: fixed;
        left: 0;
        bottom: 0;
        top: 0;
        right: 0;
        background-color: rgba(0, 0, 0, .65);
        display: flex;
        align-items: center;
        justify-content: center;

        .cookie-modal-content {
            background-color: #EFEFEF;
            border-radius: 20px;
            padding: 16px 24px 16px 24px;
            display: flex;
            flex-direction: column;
            max-width: 42rem;
            width: 100%;
            max-height: 80vh;
            overflow: auto;

            ._link {
                color: #002d7d;
            }

            .cookie-modal-header {
                display: flex;
                width: 100%;
                justify-content: space-between;
                align-items: center;

                .btn-close {
                    font-size: 32px;
                }
            }

            .cookie-modal-body {
                margin-top: 8px;

                .cookie-accordion {
                    overflow: hidden;
                    margin: 0 -24px;

                    &._mt-4 {
                        margin-top: 16px;
                    }

                    .dot {
                        width: 8px;
                        height: 8px;
                        border-radius: 50%;
                        display: block;
                        margin-right: 8px;

                        &._enabled {
                            background-color: #48bb78;
                        }

                        &._disabled {
                            background-color: #f56565;
                        }
                    }

                    .cookie-accordion-header {
                        background-color: #F9F9F9;
                        padding: 20px;
                        width: 100%;
                        border-top: 1px solid rgba(0, 0, 0, .1);
                        display: flex;
                        align-items: center;
                        justify-content: space-between;

                        svg {
                            transition: all 300ms ease-in-out;
                        }

                        &._open {
                            svg {
                                transform: rotate(-90deg);
                            }
                        }
                    }

                    .cookie-accordion-content {
                        max-height: 0;
                        background-color: white;
                        transition: all 300ms ease-in-out;

                        &._open {
                            max-height: 1000px;
                        }

                        .cookie-accordion-content-body {
                            padding: 16px;

                            &::v-deep {
                                p, ul, li {
                                    font-size: 12px;
                                }
                            }
                        }

                        .switch {
                            input {
                                display: none;

                                &:checked {
                                    & + ._label {
                                        ._switch-control {
                                            &:before {
                                                background-color: #48bb78;
                                                transform: translateX(50%);
                                            }
                                        }
                                    }
                                }
                            }

                            ._label {
                                display: flex;
                                align-items: center;
                                font-size: 12px;
                                user-select: none;

                                ._switch-control {
                                    position: relative;
                                    display: block;
                                    width: 32px;
                                    height: 22px;
                                    background-color: #F0F0F0;
                                    border: 1px solid #E3DFDF;
                                    border-radius: 50px;
                                    margin-left: 8px;
                                    margin-right: 8px;

                                    &:before {
                                        display: block;
                                        width: 20px;
                                        height: 20px;
                                        border-radius: 50%;
                                        background-color: #BEBEBE;
                                        position: absolute;
                                        top: 0;
                                        bottom: 0;
                                        transition: all 300ms ease-in-out;
                                        content: '';
                                        left: 0;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        .cookie-modal-footer {
            margin-top: 16px;
            display: flex;
            justify-content: flex-end;

            button {
                transition: all 300ms ease-in-out;
                border-radius: 30px;
                background-color: #002d7d;
                color: white;
                padding: 8px 16px;
                border: 1px solid #002d7d;

                &:hover {
                    background-color: white;
                    color: #002d7d;
                }
            }
        }
    }
}
</style>
