$(function () {
    let dropdownToggle = '.dropdown [dropdown-toggle]';

    $(document).on('click', dropdownToggle, function (e) {
        e.preventDefault();

        let nav = $(this).next('.dropdown-nav');

        // only one dropdown navigation can be opened at the time
        $.each($('.dropdown-nav'), function (index) {
            if ($('.dropdown-nav').index(nav) !== index) {
                var dropdownNav = $('.dropdown-nav').eq(index);
                if (dropdownNav.hasClass('is-visible')) {
                    dropdownNav.removeClass('is-visible');
                }
            }
        });

        if (nav.hasClass('is-visible')) {
            nav.removeClass('is-visible');
        } else {
            nav.addClass('is-visible');
        }
    });
});
