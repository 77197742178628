import Vue from 'vue'
import CookiePopUp from './components/CookiePopUp.vue';

require('./bootstrap')
require('./main')
require('./partials/on-scroll')
require('./partials/navigation')
require('./partials/dropdown')
require('./partials/modal')

Vue.component('cookie-pop-up', CookiePopUp);

new Vue({
    el: '#app'
})

var Sticky = require('sticky-js')
new Sticky('.is-sticky', {
    stickyWrap: true,
    marginTop: 110
})

let contentDataTrigger = '[data-toggle="content-data"]'

$('body').on('click', contentDataTrigger, function (e) {
    e.preventDefault()

    let $btn =  $(this),
        contentParent = $btn.parents('.content-data-section'),
        contentData = $('#' + $btn.data('id')),
        btnReadMore = contentParent.find('.btn-read-more'),
        btnH = $btn.outerHeight(),
        parentH = contentParent.outerHeight(),
        contentH = contentData.height()

    let animateTo = parentH + contentH - btnH

    if (! contentData.hasClass('active')) {
        btnReadMore.addClass('content-is-open')
        btnReadMore.one('transitionend', function (e) {
            btnReadMore.hide()
        })

        contentParent.stop().animate({ height: animateTo }, 250)

        setTimeout(function () {
            contentData.addClass('active')
        }, 250)
    } else {
        contentData.removeClass('active')

        setTimeout(function () {
            contentParent.stop().animate({ height: parentH - contentH + btnH }, 250)
        }, 125)

        setTimeout(function () {
            btnReadMore.show()
            btnReadMore.removeClass('content-is-open')
        }, 250)
    }
})

$(window).resize(function () {
    $('.content-data-section').removeAttr('style')
    $(contentDataTrigger).removeClass('content-is-open').removeAttr('style')
    $('.content-data').removeClass('active')
})

let map = document.querySelector('#map')

if (map) {
    window.initMap = function (lat, lng, zoom = 16) {
        let location = {lat: lat, lng: lng}
        let gMap = new google.maps.Map(map, {zoom: zoom, center: location})

        new google.maps.Marker({position: location, map: gMap})
    }
}
