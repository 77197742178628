var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.showPopup)?_c('div',{staticClass:"cookie-pop-up"},[_c('div',{staticClass:"cookie-content"},[_c('div',{staticClass:"cookie-body",domProps:{"innerHTML":_vm._s(_vm.translations.cookie_body)}}),_vm._v(" "),_c('div',{staticClass:"cookie-footer"},[_c('button',{staticClass:"footer-btn",attrs:{"type":"button"},domProps:{"textContent":_vm._s(_vm.translations.settings)},on:{"click":function($event){return _vm.toggleModal(true)}}}),_vm._v(" "),_c('button',{staticClass:"footer-btn _accept-btn",attrs:{"type":"button"},domProps:{"textContent":_vm._s(_vm.translations.accept_only_required)},on:{"click":_vm.acceptRequired}}),_vm._v(" "),_c('button',{staticClass:"footer-btn _accept-btn",attrs:{"type":"button"},domProps:{"textContent":_vm._s(_vm.translations.accept_all)},on:{"click":_vm.acceptAll}})])]),_vm._v(" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showModal),expression:"showModal"}],staticClass:"cookie-modal"},[_c('div',{staticClass:"cookie-modal-content"},[_c('div',{staticClass:"cookie-modal-header"},[_c('h4',{staticClass:"font-semibold",domProps:{"textContent":_vm._s(_vm.translations.modal.title)}}),_vm._v(" "),_c('button',{staticClass:"btn-close",on:{"click":function($event){return _vm.toggleModal(false)}}},[_vm._v("×")])]),_vm._v(" "),_c('div',{staticClass:"cookie-modal-body"},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.translations.cookie_body)}}),_vm._v(" "),_c('div',{staticClass:"cookie-accordion _mt-4"},[_c('button',{class:['cookie-accordion-header', {
                            '_open': _vm.accordion.required
                        }],attrs:{"type":"button"},on:{"click":function($event){return _vm.toggleAccordion('required')}}},[_c('span',{domProps:{"textContent":_vm._s(_vm.translations.modal.required_cookies)}}),_vm._v(" "),_c('svg',{staticClass:"icon-wedge-svg",staticStyle:{"opacity":"1","mix-blend-mode":"normal","fill":"rgb(51, 51, 51)","width":"32px","height":"32px"},attrs:{"version":"1.2","preserveAspectRatio":"none","viewBox":"0 0 24 24","data-id":"e9b3c566e8c14cfea38af128759b91a3"}},[_c('path',{staticStyle:{"fill":"rgb(51, 51, 51)"},attrs:{"xmlns:default":"http://www.w3.org/2000/svg","id":"angle-down","d":"M17.2,9.84c0-0.09-0.04-0.18-0.1-0.24l-0.52-0.52c-0.13-0.13-0.33-0.14-0.47-0.01c0,0-0.01,0.01-0.01,0.01  l-4.1,4.1l-4.09-4.1C7.78,8.94,7.57,8.94,7.44,9.06c0,0-0.01,0.01-0.01,0.01L6.91,9.6c-0.13,0.13-0.14,0.33-0.01,0.47  c0,0,0.01,0.01,0.01,0.01l4.85,4.85c0.13,0.13,0.33,0.14,0.47,0.01c0,0,0.01-0.01,0.01-0.01l4.85-4.85c0.06-0.06,0.1-0.15,0.1-0.24  l0,0H17.2z"}})])]),_vm._v(" "),_c('div',{class:['cookie-accordion-content', {
                        '_open': _vm.accordion.required
                    }]},[_c('div',{staticClass:"cookie-accordion-content-body",domProps:{"innerHTML":_vm._s(_vm.translations.modal.required_cookies_body)}})])]),_vm._v(" "),_c('div',{staticClass:"cookie-accordion"},[_c('button',{class:['cookie-accordion-header', {
                            '_open': _vm.accordion.marketing
                        }],attrs:{"type":"button"},on:{"click":function($event){return _vm.toggleAccordion('marketing')}}},[_c('span',{staticClass:"flex items-center"},[_c('span',{class:{
                                'dot _enabled': _vm.settings.marketing.enabled,
                                'dot _disabled': !_vm.settings.marketing.enabled,
                            }}),_vm._v(" "),_c('span',{domProps:{"textContent":_vm._s(_vm.translations.modal.marketing_cookies)}})]),_vm._v(" "),_c('svg',{staticClass:"icon-wedge-svg",staticStyle:{"opacity":"1","mix-blend-mode":"normal","fill":"rgb(51, 51, 51)","width":"32px","height":"32px"},attrs:{"version":"1.2","preserveAspectRatio":"none","viewBox":"0 0 24 24","data-id":"e9b3c566e8c14cfea38af128759b91a3"}},[_c('path',{staticStyle:{"fill":"rgb(51, 51, 51)"},attrs:{"xmlns:default":"http://www.w3.org/2000/svg","id":"angle-down","d":"M17.2,9.84c0-0.09-0.04-0.18-0.1-0.24l-0.52-0.52c-0.13-0.13-0.33-0.14-0.47-0.01c0,0-0.01,0.01-0.01,0.01  l-4.1,4.1l-4.09-4.1C7.78,8.94,7.57,8.94,7.44,9.06c0,0-0.01,0.01-0.01,0.01L6.91,9.6c-0.13,0.13-0.14,0.33-0.01,0.47  c0,0,0.01,0.01,0.01,0.01l4.85,4.85c0.13,0.13,0.33,0.14,0.47,0.01c0,0,0.01-0.01,0.01-0.01l4.85-4.85c0.06-0.06,0.1-0.15,0.1-0.24  l0,0H17.2z"}})])]),_vm._v(" "),_c('div',{class:['cookie-accordion-content', {
                        '_open': _vm.accordion.marketing
                    }]},[_c('div',{staticClass:"cookie-accordion-content-body"},[_c('p',{domProps:{"textContent":_vm._s(_vm.translations.modal.marketing_cookies_body_1)}}),_vm._v(" "),_c('div',{staticClass:"w-full flex mt-2"},[_c('div',{staticClass:"w-1/3"},[_c('div',{staticClass:"switch"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.settings.marketing.enabled),expression:"settings.marketing.enabled"}],attrs:{"id":"marketing","type":"checkbox"},domProps:{"checked":Array.isArray(_vm.settings.marketing.enabled)?_vm._i(_vm.settings.marketing.enabled,null)>-1:(_vm.settings.marketing.enabled)},on:{"change":function($event){var $$a=_vm.settings.marketing.enabled,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.settings.marketing, "enabled", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.settings.marketing, "enabled", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.settings.marketing, "enabled", $$c)}}}}),_vm._v(" "),_c('label',{staticClass:"_label",attrs:{"for":"marketing"}},[_vm._v("\n                                            "+_vm._s(_vm.translations.no)+"\n                                            "),_c('span',{staticClass:"_switch-control"}),_vm._v("\n                                            "+_vm._s(_vm.translations.yes)+"\n                                        ")])])]),_vm._v(" "),_c('div',{staticClass:"w-2/3",domProps:{"innerHTML":_vm._s(_vm.translations.modal.marketing_cookies_body_2)}})])])])])]),_vm._v(" "),_c('div',{staticClass:"cookie-modal-footer"},[_c('button',{staticClass:"mr-3",attrs:{"type":"button"},domProps:{"textContent":_vm._s(_vm.translations.save_settings)},on:{"click":_vm.acceptCurrent}}),_vm._v(" "),_c('button',{attrs:{"type":"button"},domProps:{"textContent":_vm._s(_vm.translations.accept_all)},on:{"click":_vm.acceptAll}})])])])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }