$(function () {
    let delay = 100,
        mainHeader = $('.main-header'),
        mainNav = $('.main-nav'),
        mainNavLink = mainNav.children('a'),
        navTrigger = '[nav-toggle=main-nav]',
        submenuTrigger = '[nav-toggle]',
        isAnimating = false;

    mainHeader.on('click', navTrigger, function (e) {
        e.preventDefault();

        let navIsVisible = mainHeader.hasClass('nav-is-visible');

        if (! isAnimating) {
            isAnimating = true;

            if (navIsVisible) {
                closeNav();
            } else {
                openNav();
            }
        }
    });

    let subnavBack = '.subnav-back';
    mainHeader.on('click', submenuTrigger, function (e) {
        e.preventDefault();

        let $this = $(this);
        subnav = $($this.attr('nav-toggle'));

        if (subnav.length > 0) {
            if (! subnav.hasClass('active')) {
                $this.addClass('active-subnav');
                subnav.addClass('active');
                $(subnavBack).addClass('active');
            } else {
                $this.removeClass('active-subnav');
                subnav.removeClass('active');
                $(subnavBack).addClass('active');
            }
        }
    })

    mainHeader.on('click', '.subnav-back', function (e) {
        e.preventDefault();

        let $this = $(this);

        $this.removeClass('active');
        $('.subnav').removeClass('active');
    });

    function openNav() {
        mainHeader.addClass('nav-is-visible');
        $('body').addClass('overflow-hidden');

        mainNav.one(transitionEnd, function () {
            showNavLink(0);
            mainNav.off(transitionEnd);
        });
    }

    function closeNav() {
        mainNavLink.removeClass('is-visible');

        setTimeout(function () {
            mainHeader.removeClass('nav-is-visible');
            $('body').removeClass('overflow-hidden');
            mainNav.one(transitionEnd, function () {
                mainNav.off(transitionEnd);
                isAnimating = false;
            });
        }, 200);
    }

    function showNavLink(index) {
        if (index === mainNavLink.length - 1) {
            mainNavLink.eq(index).addClass('is-visible').one(transitionEnd, function () {
                mainNavLink.eq(index).off(transitionEnd);
                isAnimating = false;
            });
        } else {
            mainNavLink.eq(index).addClass('is-visible');
            setTimeout(function () {
                showNavLink(index + 1);
            }, delay);
        }
    }
});
