$(function () {
    let modalToggle = '[modal-toggle]',
        overlay = '.overlay';

    $(document).on('click', modalToggle, function (e) {
        e.preventDefault();

        let t = $(this),
            modalId = t.attr('modal-toggle'),
            modal = $('#' + modalId);

        $('body').addClass('modal-is-visible overflow-hidden');
        modal.addClass('is-visible');

        if (modalId == 'modalSearch') {
            modal.on('transitionend', function (e) {
                e.stopPropagation();

                if (e.target.id == 'modalSearch') {
                    modal.find('input').focus();
                }
            });
        }
    });

    $(document).on('click', overlay, function (e) {
        e.preventDefault();

        $('body').removeClass('modal-is-visible overflow-hidden');
        $('.modal').removeClass('is-visible');
    });
});
